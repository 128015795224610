<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mcontacto-modals
			:modelName="modelName"
		></mcontacto-modals>
	</div>
</template>

<script>
import mcontactoActions from './McontactoActions';
import mcontactoModals from './McontactoModals.vue';

export default {
	name: 'mcontacto-grid',
	components: {
		'mcontacto-modals': mcontactoModals
	},
	data() {
		return {
			modelName: 'mcontacto',
			actions: mcontactoActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
